import Login from './login.json'
import Term from './term.json'
import Vote from './vote.json'
import Ranking from './ranking.json'

const Result = {}
Result.result = Ranking

export function getDummyJson () {
  if (process.env.VUE_APP_LOCAL_SERVER !== 'webpack') return null
  return Object.assign({}, Login, Term, Vote, Result)
}
