import { getFallbackLocale } from '@/constants/locale'

export default {
  computed: {
    locale () {
      return this.$route.params.lang ? this.$route.params.lang : getFallbackLocale().value.name
      // return getFallbackLocale().value.name
    }
  }
}
