import Vue from 'vue'
import App from './app.vue'
import router from './router'
import store from './store'
import i18n from './plugins/i18n'
import IScrollView from 'vue-iscroll-view'
import IScroll from 'iscroll'
import './assets/scss/main.scss'

// NOTE: 本番->false 開発->true
Vue.config.productionTip = true

Vue.use(IScrollView, IScroll)

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
