import Vue from 'vue'
import Vuex from 'vuex'
import login from './modules/login'
import term from './modules/term'
import vote from './modules/vote'
import modal from './modules/modal'
import result from './modules/result'
import loader from './modules/loader'
import bgm from './modules/bgm'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    login,
    term,
    vote,
    modal,
    result,
    loader,
    bgm
  }
})
