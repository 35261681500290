export default class History {
  constructor (json) {
    try {
      this.map(json)
    } catch (error) {
      // eslint-disable-next-line
      console.warn('Invalid Type: ModelHistory')
    }
  }

  map (json) {
    this.day = +json.day || 0
    this.heroId = json.hero_id || json.heroId || ''
    this.wallpaper = json.wallpaper || ''
  }

  toJSON () {
    return {
      day: this.day,
      hero_id: this.heroId,
      wallpaper: this.wallpaper
    }
  }
}
