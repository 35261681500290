class VisibilityChangeManager {
  constructor () {
    this._hidden = null
    this._visibilityChange = null
    this._handleChangeProxy = null
    this._functionsOnHidden = []
    this._functionsOnVisible = []
    this._functionsOnHiddenLen = null
    this._functionsOnVisibleLen = null

    this.setup()
  }

  setup () {
    if (typeof document.hidden !== 'undefined') {
      this._hidden = 'hidden'
      this._visibilityChange = 'visibilitychange'
    } else if (typeof document.msHidden !== 'undefined') {
      this._hidden = 'msHidden'
      this._visibilityChange = 'msvisibilitychange'
    } else if (typeof document.webkitHidden !== 'undefined') {
      this._hidden = 'webkitHidden'
      this._visibilityChange = 'webkitvisibilitychange'
    }

    this._handleChangeProxy = this.handleChange.bind(this)

    document.addEventListener(this._visibilityChange, this._handleChangeProxy, false)
  }

  handleChange () {
    document[this._hidden] ? this.callFunctionsOnHidden() : this.callFunctionsOnVisible()
  }

  addFunctionOnHidden (callback) {
    this._functionsOnHidden.push(callback)
    this._functionsOnHiddenLen = this._functionsOnHidden.length
  }

  addFunctionOnVisible (callback) {
    this._functionsOnVisible.push(callback)
    this._functionsOnVisibleLen = this._functionsOnVisible.length
  }

  callFunctionsOnHidden () {
    this._functionsOnHidden.forEach(func => func())
  }

  callFunctionsOnVisible () {
    this._functionsOnVisible.forEach(func => func())
  }

  removeFunctionsOnVisible (func) {
    let f
    let i = 0
    const len = this._functionsOnVisible.length

    for (; i < len; i++) {
      f = this._functionsOnVisible[i]
      if (f === func) {
        this._functionsOnVisible.splice(i, 1)
        break
      }
    }

    this._functionsOnVisibleLen = this._functionsOnVisible.length
  }

  removeFunctionsOnHidden (func) {
    let f
    let i = 0
    const len = this._functionsOnHidden.length

    for (; i < len; i++) {
      f = this._functionsOnHidden[i]
      if (f === func) {
        this._functionsOnHidden.splice(i, 1)
        break
      }
    }

    this._functionsOnHiddenLen = this._functionsOnHidden.length
  }

  reset () {
    // this._hidden = null
    // this._visibilityChange = null
    // this._handleChangeProxy = null
    this._functionsOnHidden = []
    this._functionsOnVisible = []
    this._functionsOnHiddenLen = 0
    this._functionsOnVisibleLen = 0
  }

  destroy () {
    this.reset()
  }
}

export default new VisibilityChangeManager()
