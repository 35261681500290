<template>
  <form class="LanguageSelect" @submit.prevent>
    <select v-model="selectLang" required class="LanguageSelect__Selecter">
      <option
        v-for="(item, i) in localeList"
        :key="i"
        :value="item.value.name"
        :selected="isSelected(item.value.name)"
      >
        {{ item.value.label }}
      </option>
    </select>
    <div class="LanguageSelect__Arrow" />
  </form>
</template>

<script>
import { locales } from '@/constants/locale'
import LocaleMixin from '@/mixins/locale'

export default {
  name: 'LanguageSelect',
  mixins: [LocaleMixin],
  computed: {
    localeList () {
      return locales.enums.map(l => l)
    }
  },
  data () {
    return {
      selectLang: null
    }
  },
  watch: {
    '$route.params.lang' (value) {
      this.selectLang = value
    },
    'selectLang' (newValue, oldValue) {
      if (!oldValue) return
      const params = this.$route.params
      params.lang = newValue
      this.$router.replace({ name: this.$route.name, params: params, query: this.$route.query }).catch(() => {})
    }
  },
  created () {
    this.selectLang = this.locale
  },
  methods: {
    isSelected (lang) {
      return lang === this.locale
    }
  }
}
</script>

<style lang="scss" scoped>
.LanguageSelect {
  @at-root {
    $_self: #{&};
    & {
      background: linear-gradient(to bottom, rgba(210,201,149,1) 0%,rgba(231,227,194,1) 40%,rgba(231,227,194,1) 100%);
      border: solid 2px * 2 #69512e;
      width: 412px * 2;
      height: 52px * 2;
      display: block;
      transform-origin: right top;
      transform: scale(0.5);
    }
    .LanguageSelect__Selecter {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      appearance: none;
      border: 0;
      margin: 0;
      background-color: transparent;
      outline: none;
      font-size: $fs-xlarge * 2;
      padding: 3px * 2 10px * 2;
      border-radius: 0;
      cursor: pointer;
    }
    .LanguageSelect__Arrow {
      position: absolute;
      top: -4px;
      right: -4px;
      width: 55px * 2;
      height: 52px * 2;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #69512e;
      pointer-events: none;
      &::before {
        content: '';
        display: block;
        background: url(#{$img-path}the-header/ico_arrow.png) no-repeat;
        background-size: 100% auto;
        width: 18px * 2;
        height: 12px * 2;
        margin-bottom: -4px;
      }
    }
    // pc
    .App__Container.-pc {
      #{$_self} {
        border-width: 2px;
        width: 412px;
        height: 52px;
        transform: scale(1);
      }
      .LanguageSelect__Selecter {
        font-size: $fs-xlarge;
        padding: 3px 10px;
      }
      .LanguageSelect__Arrow {
        top: -2px;
        right: -2px;
        width: 55px;
        height: 52px;
        &::before {
          width: 18px;
          height: 12px;
          margin-bottom: -2px;
        }
      }
    }
  }
}
</style>
