<template>
  <div class="Window" :class="{'-gradation': color === 'gradation' }">
    <div class="Window__Inner">
      <slot />
    </div>
  </div>
</template>
<script>
export default {
  name: 'Window',
  components: {
  },
  props: {
    color: {
      type: String
    }
  }
}
</script>

<style lang="scss" scoped>
.Window {
  @at-root {
    & {
      border-style: solid;
      border-width: 36px 65px 68px 65px;
      border-image: url(#{$img-path}common/bg_window-blue.png) 36 65 68 65 fill stretch;
      width: 580px;
      margin: 0 auto;
      &.-gradation {
        border-image: url(#{$img-path}common/bg_window-gradation.png) 36 65 68 65 fill stretch;
      }
    }
    .Window__Inner {
      margin: -16px -48px;
    }
  }
}
</style>
