import { getServerParam } from '@/constants/term'

export default {
  namespaced: true,

  state: {
    voteOpen: false,
    resultOpen: false,
    isLastDay: false,
    day: 0,
    isClose: false
  },

  getters: {
    voteOpen (state) {
      return state.voteOpen
    },
    resultOpen (state) {
      return state.resultOpen
    },
    isLastDay (state) {
      return state.isLastDay
    },
    isClose (state) {
      return state.isClose
    }
  },

  mutations: {
    set (state, term) {
      state.voteOpen = term.isOpen
      state.resultOpen = term.isResult
      state.isLastDay = term.isLastDay
      state.day = +term.day
      state.isClose = term.isClose
    }
  },

  actions: {
    init ({ commit }) {
      const term = getServerParam()
      commit('set', term)
    }
  }
}
