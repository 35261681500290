export default class Result {
  constructor (json) {
    try {
      this.map(json)
    } catch (error) {
      // eslint-disable-next-line
      console.warn('Invalid Type: ModelHistoru')
    }
  }

  map (json) {
    this.heroId = json.hero_id || ''
    this.rank = json.rank || ''
    this.voteCount = +json.vote_count || 0
  }

  toJSON () {
    return {
      heroId: this.heroId,
      rank: this.rank,
      voteCount: this.voteCount
    }
  }
}
