export default class Modal {
  constructor (object) {
    try {
      this.map(object)
    } catch (e) {
      console.warn('Invalid Type: ModelModal')
    }
  }

  map (object) {
    this.component = object.component
    this.props = object.props || null
  }
}
