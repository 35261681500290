<template>
  <div
    v-if="isShowTerms"
    class="TermsModal"
    @touchmove.prevent
    @touchend.prevent
  >
    <iscroll-view
      :options="iScrollView.options"
      :scrollerClass="{ TermsModal__ScrollerInner: true }"
      ref="iscroll"
      class="TermsModal__Scroller"
    >
      <div class="TermsModal__Content">
        <btn-close class="TermsModal__BtnClose" @click="close" />
        <p class="TermsModal__Title" v-html="$t('voteAgreement.001')" />
        <p class="TermsModal__Subtitle" v-html="$t('voteAgreement.002')" />
        <div class="TermsModal__Details" v-html="$t('voteAgreement.003')" />
      </div>
    </iscroll-view>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import BtnClose from '@/components/modals/button-close.vue'

export default {
  name: 'TermsModal',
  components: {
    BtnClose
  },
  data () {
    return {
      iScrollView: {
        options: {
          scrollbars: true,
          fadeScrollbars: true,
          shrinkScrollbars: 'scale',
          mouseWheel: true,
          click: true
        }
      }
    }
  },
  computed: {
    ...mapGetters('modal', ['isShowTerms'])
  },
  watch: {
    isShow (val) {
      if (!val) {
        return
      }
      this.$nextTick(() => {
        this.refreshIScroll()
      })
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.refreshIScroll()
    })
  },
  methods: {
    ...mapActions('modal', { close: 'toggleTerms' }),
    refreshIScroll () {
      const $iscroll = this.$refs.iscroll
      if (!$iscroll) {
        return
      }
      setTimeout(() => {
        $iscroll.refresh()
        $iscroll.scrollTo(0, 0, 0)
      }, 100)
    }
  }
}
</script>

<style lang="scss" scoped>
.TermsModal {
  @at-root {
    & {
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: map-get($z-index, modal);
      backface-visibility: hidden;
      overflow: hidden;
      background-color: rgba($color-sub, 0.8);
    }
    & /deep/ a {
      color: $color-active;
      text-decoration: underline;
      @include tap-event();
    }
    .TermsModal__Scroller {
      touch-action: none;
      width: 100%;
      height: 100%;
    }
    .TermsModal__Content {
      position: relative;
      width: $base-w;
      height: 100%;
      margin: auto;
      padding: 20px;
    }
    .TermsModal__Title {
      margin: 20px 0 10px;
      padding: 0 110px;
      font-size: $fs-3xlarge;
      line-height: 1.4;
      text-align: center;
    }
    .TermsModal__Subtitle {
      margin-bottom: 30px;
      padding: 0 110px;
      text-align: center;
    }
    .TermsModal__Details {
      padding: 40px 0;
      word-break: break-word;
      overflow-wrap: break-word;
    }
    .TermsModal__BtnClose {
      position: absolute;
      top: 15px;
      right: 15px;
      z-index: 1;
    }
  }
}
</style>
