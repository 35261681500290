export default class User {
  constructor (obj) {
    try {
      this.map(obj)
    } catch (e) {
      console.warn('Invalid Type: ModelUser')
    }
  }

  map (obj) {
    this.userId = obj.na_user_id || ''
    this.nickname = obj.nickname || ''
    this.isCoppa = obj.is_coppa || false
    this.image = obj.mii_image_url || ''
  }
}
