<template>
  <div class="NaLogin">
    <window class="NaLogin__Window">
      <img class="NaLogin__Logo" src="@images/modal/icon_logo.png" alt="My Nintendo" height="142" width="142">
      <template v-if="fromMyPage">
        <p class="NaLogin__Guide" v-html="$t('modal.connect-003')" />
      </template>
      <template v-else>
        <p class="NaLogin__Guide" v-html="$t('modal.connect-001')" />
      </template>
      <p class="NaLogin__Text" v-html="$t('modal.connect-005')" />
      <ul class="NaLogin__List">
        <li class="NaLogin__ListItem" v-html="$t('modal.connect-006')" />
        <li class="NaLogin__ListItem" v-html="$t('modal.connect-007')" />
      </ul>
      <btn-blue class="NaLogin__Button" @click="onClickLogin">
        <span v-if="fromMyPage" v-html="$t('modal.connect-004')" />
        <span v-else v-html="$t('modal.connect-002')" />
      </btn-blue>
      <a
        href="https://my.nintendo.com/getting_started"
        class="NaLogin__Link"
        target="_blank"
        rel="noopener noreferrer"
        v-html="$t('modal.connect-008')"
      />
    </window>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import LocaleMixin from '@/mixins/locale'
import Window from '@/components/global/window.vue'
import BtnBlue from '@/components/global/button-blue.vue'

export default {
  name: 'NaLogin',
  mixins: [LocaleMixin],
  components: {
    Window,
    BtnBlue
  },
  computed: {
    ...mapGetters('modal', ['modal']),
    fromMyPage () {
      return this.modal.props.toPath === 'mypage'
    },
    redirectPath () {
      const host = !process.env.VUE_APP_LOCAL_SERVER ? process.env.VUE_APP_URL : location.origin
      const path = `/${this.locale}/${this.modal.props.toPath}`
      return this.modal.props.toPath ? `${host}${path}` : location.href
    }
  },
  methods: {
    onClickLogin () {
      location.href = `/login?redirect=${this.redirectPath}`
    }
  }
}
</script>

<style lang="scss" scoped>
.NaLogin {
  @at-root {
    & {
      text-align: center;
    }

    & /deep/ .Window__Inner {
      padding: 55px 23px;
    }
    .NaLogin__Guide,
    .NaLogin__Text {
      font-weight: $bold;
      text-shadow: $ts-base;
      display: block;
    }
    .NaLogin__Guide {
      margin: 25px auto;
      font-size: $fs-2xlarge;
    }

    .NaLogin__Text {
      margin: 50px auto 25px;
      font-size: $fs-3large;
    }
    .NaLogin__List {
      width: 460px;
      margin: 0 auto 20px;
      text-align: left;
    }
    .NaLogin__ListItem {
      font-size: $fs-large;
      text-shadow: $ts-base;
      padding-left: 40px;
      position: relative;
      &:before {
        content: '';
        display: inline-block;
        background-image: url(#{$img-path}common/icon_bullet.png);
        background-repeat: no-repeat;
        background-size: 20px 20px;
        background-position: 0 8px;
        position: absolute;
        left: 0;
        width: 30px;
        height: 30px;
      }
    }
    .NaLogin__Button {
      font-size: $fs-3xlarge;
      text-shadow: $ts-base;
      display: block;
      margin: 50px auto 15px;
      > * {
        display: block;
        width: 100%;
      }
    }
    .NaLogin__Link {
      display: inline-block;
      margin: 10px 0;
      padding-left: 40px;
      position: relative;
      color: $color-base;
      text-shadow: $ts-base;
      font-size: $fs-large;
      &:before {
        content: '';
        display: inline-block;
        background-image: url(#{$img-path}common/icon_arrow.png);
        background-repeat: no-repeat;
        background-size: 100%;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: 40px;
        height: 29px;
      }
    }
  }
}
</style>
