<template>
  <div class="PageLoader" :class="classes">
    <loader color="gold" />
  </div>
</template>

<script>
import Loader from '@/components/global/loader.vue'

export default {
  name: 'PageLoader',
  components: {
    Loader
  },
  computed: {
    classes () {
      if (!this.$route.name) {
        return
      }
      const routeName = this.$route.name
      const routeClass = `-${routeName.charAt(0).toLowerCase() + routeName.slice(1)}`
      return [
        routeClass
      ]
    }
  },
  mounted () {
    document.addEventListener('touchmove', this.scrollLock, { passive: false })
    document.addEventListener('mousewheel', this.scrollLock, { passive: false })
  },
  destroyed () {
    document.removeEventListener('touchmove', this.scrollLock, { passive: false })
    document.removeEventListener('mousewheel', this.scrollLock, { passive: false })
  },
  methods: {
    scrollLock (event) {
      event.preventDefault()
    }
  }
}
</script>

<style lang="scss" scoped>
.PageLoader {
  @at-root {
    & {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: map-get($z-index, loader);
      backface-visibility: hidden;
      display: flex;
      flex-direction: column;
      justify-content: center;
      &::after {
        content: '';
        display: block;
        width: 640px;
        position: fixed;// @FIXME fixed内のfixed指定は機能しない
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        background-image: url(#{$img-path}bg_main-top.png), url(#{$img-path}bg_main-base.png);
        background-repeat: no-repeat, repeat-y;
        background-position: 0 0, 0 88px;
        background-color: $color-sub;
      }
      &.-vote::after {
        background-image: url(#{$img-path}bg_light.png), url(#{$img-path}bg_2-base.png);
        background-position: center -90px, 0 0;
      }
      &.-hero::after,
      &.-heroVoted::after {
        background-image: url(#{$img-path}hero/bg.png);
        background-position: center 0;
      }
      &.-error404::after,
      &.-error::after {
        background-image: url(#{$img-path}bg_3.png);
        background-position: center 0;
        background-size: cover;
      }


    }
  }
}
</style>
