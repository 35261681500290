const ua = window.navigator.userAgent
export const isIE = ua.match(/(msie|MSIE)/) || ua.match(/(T|t)rident/)
export const isEdge = ua.indexOf('Edge') !== -1
export const isPC = !ua.match(/(iPhone|iPad|iPod|Android)/i)

/**
 * 数字を区切り文字を入れた文字列にして返す
 * @param {number} num
 * @param {string} locale
 * @return {string}
 */
export function delimit (num, locale) {
  const regexp = new RegExp(/(\d)(?=(\d{3})+(?!\d))/g)
  const key = locale.toLowerCase()
  let delimiter = ','

  switch (key) {
    case 'de-de':
    case 'es-es':
    case 'es-mx':
    case 'fr-ca':
    case 'fr-fr':
      delimiter = ' '
      break
    case 'it-it':
    case 'pt-br':
      delimiter = '.'
      break
    default:
      delimiter = ','
  }

  return `${num}`.replace(regexp, `$1${delimiter}`)
}
