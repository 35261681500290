import { getServerParam } from '@/constants/result'
import ModelResult from '@/models/result'

export default {
  namespaced: true,

  state: {
    ranking: []
  },

  getters: {
    getRanking (state) {
      return state.ranking.sort((a, b) => +a.rank < +b.rank ? -1 : 1)
    },
    getTopEightRanking (state) {
      return state.ranking.filter(r => +r.rank < 9).sort((a, b) => +a.rank < +b.rank ? -1 : 1)
    }
  },

  mutations: {
    setResult (state, result) {
      state.ranking = result.ranking.map(r => new ModelResult(r))
    }
  },

  actions: {
    init ({ commit }) {
      const result = getServerParam()
      commit('setResult', result)
    }
  }
}
