export default class Hero {
  constructor (json) {
    try {
      this.map(json)
    } catch (error) {
      // eslint-disable-next-line
      console.warn('Invalid Type: ModelHero')
    }
  }

  map (json) {
    this.heroId = json.hero_id || ''
    this.heroType = json.hero_type || ''
    this.moveType = json.move_type || ''
    this.weaponType = json.weapon_type || ''
    this.skillAtk = json.skill_atk || ''
    this.skillSprt = json.skill_sprt || ''
    this.skillSp = json.skill_sp || ''
    this.skillA = json.skill_a || ''
    this.skillB = json.skill_b || ''
    this.skillC = json.skill_c || ''
    this.densyou = json.densyou || ''
    this.touchCount = +json.touch_count || 0
  }

  toJSON () {
    return {
      heroId: this.heroId,
      heroType: this.heroType,
      moveType: this.moveType,
      weapon: this.weapon,
      skillAtk: this.skillAtk,
      skillSprt: this.skillSprt,
      skillSp: this.skillSp,
      skillA: this.skillA,
      skillB: this.skillB,
      skillC: this.skillC,
      densyou: this.densyou,
      touchCount: this.touchCount
    }
  }
}
