<template>
  <header class="Header" v-if="!hideHeader">
    <button v-if="!isClose" class="Header__MypageButton" @click="clickMyPageBtn">
      <img
        :src="require(`@images/the-header/${locale}/btn_text_mypage.png`)"
        :alt="$t('common.header-001')"
      >
    </button>
    <language-select class="Header__LanguageSelect" />
  </header>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import LocaleMixin from '@/mixins/locale'
import ModelModal from '@/models/modal'
import NaLogin from '@/components/modals/na-login.vue'
import LanguageSelect from '@/components/global/language-select.vue'

export default {
  mixins: [LocaleMixin],
  components: {
    LanguageSelect
  },
  computed: {
    ...mapGetters('login', ['isLogin']),
    ...mapGetters('term', ['isClose']),
    hideHeader () {
      const hideRouteNames = ['Error404', 'Error']
      return hideRouteNames.some(r => r === this.$route.name)
    }
  },
  methods: {
    ...mapActions('modal', { showModal: 'show' }),

    clickMyPageBtn () {
      if (this.$route.name === 'MyPage') {
        location.reload()
        return
      }
      if (this.isLogin) {
        this.$router.push({ name: 'MyPage', params: { lang: this.locale } })
      } else {
        this.showModal(new ModelModal({
          component: NaLogin,
          props: {
            hideCloseBtn: false,
            toPath: 'mypage'
          }
        }))
      }
    }
  }
}

</script>

<style lang="scss" scoped>
.Header {
  @at-root {
    & {
      position: relative;
      overflow: hidden;
      height: 122px;
      margin-bottom: -32px;
      z-index: map-get($z-index, header);
      background: url(#{$img-path}the-header/bg_header.png) no-repeat center -13px;
    }
    .Header__MypageButton {
      position: relative;
      top: -18px;
      left: -5px;
      width: 142px;
      height: 143px;
      display: block;
      background: url(#{$img-path}the-header/btn_bg_mypage.png) no-repeat;
      @include tap-event();
      > img {
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        bottom: 35px;
      }
    }
    .Header__LanguageSelect {
      position: absolute;
      top: 18px;
      right: 20px;
    }
  }
}
</style>
