export default {
  namespaced: true,
  state: {
    loading: true
  },
  getters: {
    isLoading (state) {
      return state.loading
    }
  },
  mutations: {
    set (state, bool) {
      state.loading = bool
    }
  },
  actions: {
    start ({ commit }) {
      commit('set', true)
    },
    end ({ commit }) {
      commit('set', false)
    }
  }
}
