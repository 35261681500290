export default {
  namespaced: true,

  state: {
    isPlaying: false,
    currentBgm: new Audio()
  },

  getters: {
    isPlaying (state) {
      return state.isPlaying
    },
    currentBgm (state) {
      return state.currentBgm
    }
  },

  mutations: {
    setBgmState (state, bgm) {
      state.isPlaying = bgm.isPlaying || false
    }
  },

  actions: {
    update ({ commit }, bgm) {
      commit('setBgmState', bgm)
    }
  }
}
