import Vue from 'vue'
import VueI18n from 'vue-i18n'
import Character from '@/locales/character.yml'
import Common from '@/locales/common.yml'
import Errors from '@/locales/error.yml'
import Modal from '@/locales/modal.yml'
import MyPage from '@/locales/mypage.yml'
import Nickname from '@/locales/nickname.yml'
import Parameters from '@/locales/parameters.yml'
import Result from '@/locales/result.yml'
import Top from '@/locales/top.yml'
import Vars from '@/locales/vars.yml'
import VoteAgreement from '@/locales/vote_agreement.yml'
import Vote from '@/locales/vote.yml'
import { locales, getFallbackLocale } from '@/constants/locale'

const messages = {}
for (const item of locales.enums.map(l => l.value.name)) {
  messages[item] = {
    character: Character[item],
    common: Common[item],
    errors: Errors[item],
    modal: Modal[item],
    mypage: MyPage[item],
    nickname: Nickname[item],
    parameters: Parameters[item],
    result: Result[item],
    top: Top[item],
    vars: Vars[item],
    voteAgreement: VoteAgreement[item],
    vote: Vote[item]
  }
}

Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: getFallbackLocale().value.name,
  messages: messages
})

export default i18n
