<template>
  <div class="AppDownload">
    <img class="AppDownload__Icon" src="@images/app-download/ico_app.png" alt="appIcon" />
    <a
      class="AppDownload__AppStore"
      :href="$t('vars.appstore')"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img :src="require(`@images/app-download/${locale}/appstore-lrg.png`)" height="76" alt="app store" />
    </a>
    <a
      class="AppDownload__GooglePlay"
      :href="$t('vars.googleplay')"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img :src="require(`@images/app-download/${locale}/google-play-badge.png`)" height="76" alt="google play" />
    </a>
  </div>
</template>

<script>
import LocaleMixin from '@/mixins/locale'

export default {
  name: 'AppDownload',
  mixins: [LocaleMixin]
}
</script>

<style lang="scss" scoped>
.AppDownload {
  @at-root {
    & {
      display: flex;
      justify-content: center;
      margin-bottom: 50px;
      img {
        display: block;
      }
    }
    .AppDownload__Icon {
      width: 83px;
      margin-right: 10px;
    }
    .AppDownload__AppStore {
      margin-right: 10px;
    }
  }
  a {
    @include tap-event();
  }
}
</style>
