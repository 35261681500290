<template>
  <component
    :is="tag"
    @click.prevent="$emit('click')"
    class="ModalCloser"
  >
    <img :src="require(`@images/common/${locale}/btn_close.png`)" :alt="$t('common.002')">
  </component>
</template>

<script>
import LocaleMixin from '@/mixins/locale'

export default {
  name: 'ButtonClose',
  mixins: [LocaleMixin],
  props: {
    type: {
      type: String,
      default: null
    },
    tag: {
      type: String,
      default: 'button'
    }
  }
}
</script>

<style lang="scss" scoped>
.ModalCloser {
  @at-root {
    & {
      @include tap-event();
      display: inline-block;
    }
  }
}
</style>
