import { getDummyJson } from './dummydata/index.js'

export const getServerParam = () => {
  let config = {}
  const element = document.getElementById('config')
  if (element && element.tagName.toLowerCase() === 'script') {
    try {
      config = JSON.parse(element.innerHTML)
    } catch {
      console.error({ error: 'JSON parse error' })
    }
  } else {
    config = getDummyJson()
  }
  return Object.assign({}, { isLogin: config.is_login }, { user: config.me })
}
