import ModelModal from '@/models/modal'

export default {
  namespaced: true,

  state: {
    modal: null,
    isShow: false,
    isShowTerms: false
  },

  getters: {
    isSet (state) {
      return !!state.modal
    },
    isShow (state) {
      return state.isShow
    },
    modal (state) {
      return state.modal
    },
    isShowTerms (state) {
      return state.isShowTerms
    }
  },

  mutations: {
    set (state, value) {
      if (value instanceof ModelModal) {
        state.modal = value
        state.isShow = true
      } else {
        console.warn('model/modalを適用してください')
      }
    },

    hide (state) {
      state.isShow = false
    },

    setTerms (state, status) {
      state.isShowTerms = status || !state.isShowTerms
    },

    reset (state) {
      state.modal = null
    }
  },

  actions: {
    show ({ commit }, modal) {
      commit('set', modal)
    },

    hide ({ commit }) {
      commit('hide')
    },

    toggleTerms ({ commit }, status) {
      commit('setTerms', status)
    },

    reset ({ commit }) {
      commit('reset')
    }
  }
}
