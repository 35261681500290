<template>
  <div id="app" class="App">
    <div class="App__Container" :class="[routeClass, deviceClass]">
      <main-header />
      <router-view />
      <main-footer />
    </div>
    <modal />
    <terms-modal />
    <loader v-if="isLoading" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import VisibilityChangeManager from '@/libraries/VisibilityChangeManager'
import { validateLocale, getLocale } from '@/constants/locale'
import MainHeader from '@/components/the-header.vue'
import MainFooter from '@/components/the-footer.vue'
import Modal from '@/components/modal.vue'
import TermsModal from '@/components/modals/terms.vue'
import Loader from '@/components/the-loader.vue'
import { isPC } from '@/libraries/util'

export default {
  components: {
    MainHeader,
    MainFooter,
    Modal,
    TermsModal,
    Loader
  },
  watch: {
    '$route.params.lang' (newVal, oldVal) {
      if (oldVal && newVal !== oldVal) {
        this.$i18n.locale = newVal
      }
    }
  },
  data () {
    return {
      deviceClass: {
        '-pc': isPC,
        '-mobile': !isPC
      }
    }
  },
  computed: {
    ...mapGetters('term', ['resultOpen']),
    ...mapGetters('loader', ['isLoading']),
    ...mapGetters('bgm', ['isPlaying', 'currentBgm']),
    routeClass () {
      if (!this.$route.name) {
        return
      }
      const routeName = this.$route.name
      const routeClass = `-${routeName.charAt(0).toLowerCase() + routeName.slice(1)}`
      return routeClass
    }
  },
  created () {
    const locale = location.pathname.split('/')[1]
    if (!validateLocale(locale)) {
      const localePath = getLocale().value.name
      location.href = `/${localePath}`
      return false
    }
    this.$i18n.locale = locale
    this.initLogin()
    this.initTerm()
    this.initVote()
    if (this.resultOpen) this.initResult()
    this.initBgm()
  },
  destroyed () {
    VisibilityChangeManager.removeFunctionsOnVisible(this.handleVisible)
    VisibilityChangeManager.removeFunctionsOnHidden(this.handleInvisible)
  },
  methods: {
    ...mapActions('login', { initLogin: 'init' }),
    ...mapActions('term', { initTerm: 'init' }),
    ...mapActions('vote', { initVote: 'init' }),
    ...mapActions('result', { initResult: 'init' }),
    initBgm () {
      VisibilityChangeManager.addFunctionOnVisible(this.handleVisible)
      VisibilityChangeManager.addFunctionOnHidden(this.handleInvisible)
    },
    handleVisible () {
      if (!this.isPlaying) {
        return
      }
      this.currentBgm.play()
    },
    handleInvisible () {
      if (!this.isPlaying) {
        return
      }
      this.currentBgm.pause()
    }
  }
}
</script>

<style lang="scss">
.App {
  @at-root {
    & {
      font-family: $ff-lang-jp;
      width: 100%;
      background: url(#{$img-path}bg_pc.jpg) no-repeat;
      background-position: center top;
      background-size: 100vw auto;
      background-attachment: fixed;
    }
    .App__Container {
      position: relative;
      width: $base-w;
      height: 100%;
      min-height: 100vh;
      display: flex;
      flex-direction: column;
      margin: 0 auto;
      overflow: hidden;
      background-color: $color-sub;
      background-image: url(#{$img-path}bg_main-top.png), url(#{$img-path}bg_main-base.png);
      background-repeat: no-repeat, repeat-y;
      background-position: 0 0, 0 88px;
      &.-vote {
        background-image: url(#{$img-path}bg_light.png),url(#{$img-path}bg_2-base.png);
        background-position: center -90px, 0 0;
      }
      &.-hero,
      &.-heroVoted {
        background-image: url(#{$img-path}hero/bg.png);
        background-position: center 0;
      }
      &.-error404,
      &.-error {
        background-image: url(#{$img-path}bg_3.png);
        background-position: center 0;
        background-size: cover;
      }
    }
  }
}
</style>
