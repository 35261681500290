<template>
  <div class="SnsShare" :class="{'-horizontal': layout === 'horizontal'}">
    <div class="SnsShare__Heading">
       <img v-if="layout === 'horizontal'"
        :src="require(`@images/hero/${locale}/text_share-info.png`)"
        :alt="$t('common.footer-002')"
      >
      <img v-else
        :src="require(`@images/sns-share/${locale}/text_share.png`)"
        :alt="$t('common.footer-001')"
      >
    </div>
    <ul class="SnsShare__List">
      <li class="SnsShare__ListItem">
        <button class="SnsShare__Button -facebook" @click="clickFacebookShare">facebook</button>
      </li>
      <li class="SnsShare__ListItem">
        <button class="SnsShare__Button -twitter" @click="clickTwitterShare">twitter</button>
      </li>
      <li class="SnsShare__ListItem" v-if="locale === 'ja-jp'">
        <button class="SnsShare__Button -line" @click="clickLineShare">line</button>
      </li>
    </ul>
  </div>
</template>

<script>
import LocaleMixin from '@/mixins/locale'
import ModelHero from '@/models/hero'

export default {
  name: 'SnsShare',
  mixins: [LocaleMixin],
  props: {
    layout: {
      type: String,
      default: 'vertical'
    },
    hero: {
      type: Object,
      require: false,
      validator (val) {
        return val instanceof ModelHero
      }
    }
  },
  computed: {
    shareText () {
      let text = this.$t('common.share-top')
      switch (this.$route.name) {
        case 'Result':
          text = this.$t('common.share-result')
          break
        case 'ResultList':
          text = this.$t('common.share-result')
          break
        case 'Hero':
          text = this.$t('common.share-cheer')
          text = text.replace(/{hero\.name}/g, this.$t(`character.${this.$route.params.heroId}`))
          break
        case 'HeroVoted':
          text = this.$t('common.share-voted')
          text = text.replace(/{hero\.name}/g, this.$t(`character.${this.$route.params.heroId}`))
          break
      }
      return text
    },
    shareUrl () {
      let url = `${process.env.VUE_APP_URL}/${this.locale}?locale=${this.locale}`
      if (this.hero) {
        url = `${url}&hero_id=${this.hero.heroId}`
        switch (this.$route.name) {
          case 'Hero':
            url = `${url}&share=cheer`
            break
          case 'HeroVoted':
            url = `${url}&share=vote`
            break
        }
      }
      return url
    },
    gaEventAction () {
      let action = 'share_snsplatform'
      switch (this.$route.name) {
        case 'Hero':
          action = 'share_snsplatform_cheer'
          break
        case 'HeroVoted':
          action = 'share_snsplatform_voted'
          break
        case 'Result':
          action = 'share_snsplatform_result'
          break
        case 'ResultList':
          action = 'share_snsplatform_result'
      }
      return action
    }
  },
  methods: {
    clickFacebookShare () {
      window.open(`https://www.facebook.com/sharer.php?u=${encodeURIComponent(this.shareUrl)}`, '_blank', 'noopener noreferrer')
    },
    clickTwitterShare () {
      const text = this.shareText.replace(/(<br>|{url})/g, '')
      window.open(`http://twitter.com/intent/tweet?url=${encodeURIComponent(this.shareUrl)}&text=${encodeURIComponent(text)}`, '_blank', 'noopener noreferrer')
    },
    clickLineShare () {
      const text = this.shareText.replace(/<br>/g, '').replace(/{url}/g, this.shareUrl)
      window.open(`http://line.me/R/msg/text/?${encodeURIComponent(text)}`, '_blank', 'noopener noreferrer')
    }
  }
}
</script>

<style lang="scss" scoped>
.SnsShare {
  @at-root {
    & {
      border-top: 1px solid #465343;
    }
    &.-horizontal {
      border-top: none;
      background: url(#{$img-path}sns-share/bg_share-buttons.png) repeat-x center;
      display: flex;
      align-items: center;
      padding: 0 30px;
      .SnsShare__Heading {
        width: 280px;
        padding: 0 0 10px 0;
        text-align: left;
      }
      .SnsShare__List {
        background-image: none;
      }
      .SnsShare__ListItem + .SnsShare__ListItem {
        margin-left: 22px;
      }
    }
    .SnsShare__Heading {
      padding: 20px 0;
      text-align: center;
    }
    .SnsShare__List {
      display: flex;
      justify-content: center;
      width: 100%;
      height: 104px;
      padding-top: 7px;
      background: url(#{$img-path}sns-share/bg_share-buttons.png) repeat-x;
    }
    .SnsShare__ListItem {
      width: 80px;
      height: 80px;
    }
    .SnsShare__ListItem + .SnsShare__ListItem {
      margin-left: 44px;
    }
    .SnsShare__Button {
      display: block;
      width: 80px;
      height: 80px;
      @include hidden-text();
      @include tap-event();
      &.-facebook {
        background: url(#{$img-path}sns-share/icon_facebook.png) no-repeat;
      }
      &.-twitter {
        background: url(#{$img-path}sns-share/icon_twitter.png) no-repeat;
      }
      &.-line {
        background: url(#{$img-path}sns-share/icon_line.png) no-repeat;
      }
    }
  }
}
</style>
