<template>
  <div class="FooterArea">
    <template v-if="showStoreAndShare">
      <app-download />
      <div class="FooterArea__Container">
        <sns-share />
        <p class="FooterArea__Trademark" v-html="$t('common.footer-004')" />
      </div>
    </template>
    <footer v-if="!hideFooter" class="FooterArea__Footer Footer">
      <div v-text="$t('common.footer-003')" class="Footer__Link" @click="showTerms" />
      <small class="Footer__Copyright" v-html="$t('common.footer-005')" />
    </footer>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import AppDownload from '@/components/global/app-download.vue'
import SnsShare from '@/components/global/sns-share.vue'

export default {
  components: {
    AppDownload,
    SnsShare
  },
  computed: {
    showStoreAndShare () {
      const showRouteNames = ['Top', 'Result', 'ResultList']
      return showRouteNames.some(r => r === this.$route.name)
    },
    hideFooter () {
      const hideRouteNames = ['Vote', 'HeroList']
      return hideRouteNames.some(r => r === this.$route.name)
    }
  },
  methods: {
    ...mapActions('modal', { showTerms: 'toggleTerms' })
  }
}
</script>

<style lang="scss" scoped>
.FooterArea {
  @at-root {
    & {
      margin-top: auto;
    }
    .FooterArea__Container {
      background: url(#{$img-path}sns-share/bg_share.png) repeat-y 0 center;
      border-top: 1px solid #465343;
    }
    .FooterArea__Trademark {
      padding: 30px 20px;
    }
  }
}

.Footer {
  @at-root {
    & {
      min-height: 122px;
      background-image: url(#{$img-path}the-footer/bg_top.png),
                        url(#{$img-path}the-footer/bg_bottom.png);
      background-repeat: no-repeat, no-repeat;
      background-position: center 0, center bottom;
      background-size: 100% 2px, 100% 70px;
      background-color: #0d151a;
      text-align: center;
      padding: 20px;
      box-shadow: 0px -5px 9px 0px rgba(0,0,0,.17);
    }
    .Footer__Link {
      margin-bottom: 16px;
      position: relative;
      display: inline-block;
      padding-left: 44px;
      @include tap-event();
      &::before {
        position: absolute;
        top: 4px;
        left: 0;
        content: '';
        display: block;
        background-image: url(#{$img-path}common/icon_arrow.png);
        width: 40px;
        height: 28px;
      }
    }
    .Footer__Copyright {
      display: block;
      font-size: $fs-middle;
    }
  }
}
</style>
