<template>
  <div
    v-if="isShow"
    class="Modal"
    @touchmove.prevent
    @touchend.prevent
  >
    <iscroll-view
      :options="iScrollView.options"
      :scrollerClass="{ Modal__ScrollerInner: true }"
      ref="iscroll"
      class="Modal__Scroller"
    >
      <div class="Modal__Inner">
        <component :is="modal.component" />
        <btn-close class="Modal__BtnClose" :class="{'-hide': hideCloseBtn}" @click="closeModal" />
      </div>
    </iscroll-view>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import BtnClose from '@/components/modals/button-close.vue'

export default {
  name: 'modal',
  components: {
    BtnClose
  },
  data () {
    return {
      iScrollView: {
        options: {
          scrollbars: true,
          fadeScrollbars: true,
          shrinkScrollbars: 'scale',
          mouseWheel: true,
          click: true
        }
      }
    }
  },
  computed: {
    ...mapGetters('modal', ['isShow', 'modal']),
    hideCloseBtn () {
      return this.modal.props.hideCloseBtn || false
    }
  },
  watch: {
    '$route.path' () {
      if (!this.isShow) {
        return
      }
      this.closeModal()
    },
    isShow (val) {
      if (!val) {
        return
      }
      this.$nextTick(() => {
        this.refreshIScroll()
      })
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.refreshIScroll()
    })
  },
  methods: {
    ...mapActions('modal', ['hide', 'reset']),
    closeModal () {
      this.hide()
      this.reset()
    },
    refreshIScroll () {
      const $iscroll = this.$refs.iscroll
      if (!$iscroll) {
        return
      }
      setTimeout(() => {
        $iscroll.refresh()
        $iscroll.scrollTo(0, 0, 0)
      }, 100)
    }
  }
}
</script>

<style lang="scss" scoped>
.Modal {
  @at-root {
    & {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      z-index: map-get($z-index, modal);
      backface-visibility: hidden;
      overflow: hidden;
      background-color: rgba($color-sub, 0.8);
    }
    .Modal__Scroller {
      touch-action: none;
      width: 100%;
      height: 100%;
    }
    .Modal__Inner {
      position: relative;
      width: 580px;
      margin: 0 auto;
      padding: 70px 0 40px;
    }
    // NOTE: override generic component
    .Modal__BtnClose {
      position: absolute;
      top: 50px;
      right: -20px;
      &.-hide {
        display: none;
      }
    }
  }
}
</style>
