import { getServerParam } from '@/constants/login'
import ModelUser from '@/models/user'

export default {
  namespaced: true,
  state: {
    isLogin: false,
    user: null
  },
  getters: {
    isLogin (state) {
      return state.isLogin
    },
    user (state) {
      return state.user
    }
  },
  mutations: {
    setUser (state, user) {
      state.user = new ModelUser(user)
    },
    setLogin (state, isLogin) {
      state.isLogin = isLogin
    }
  },
  actions: {
    init ({ commit }) {
      const params = getServerParam()
      if (params) {
        commit('setUser', params.user)
        commit('setLogin', params.isLogin)
      }
    }
  }
}
