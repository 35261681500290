<template>
  <button
    :style="{ width: `${width}px`, height: `${height}px` }"
    :class="{'-halfHeight': height < 61}"
    class="ButtonBlue"
    :disabled="disabled"
    @click="$emit('click')"
  >
    <slot />
  </button>
</template>
<script>
export default {
  props: {
    width: {
      type: Number,
      default: 500
    },
    height: {
      type: Number,
      default: 110
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
}
</script>
<style lang="scss" scoped>
.ButtonBlue {
  @include tap-event();
  position: relative;
  text-align: center;
  color: $color-base;
  border-style: solid;
  border-width: 0px 64px;
  border-image: url(#{$img-path}button-blue/bg_btn.png) 0 64 fill stretch;
  > * {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -60%);
  }
  &.-halfHeight {
    border-width: 0px 40px;
  }
  &:disabled {
    > * {
      opacity: 0.5;
    }
  }
}
</style>
